// Colors
$blue: #3d5af1;
$white: #fff;
$super-light-gray: #fbfbfb;
$light-gray: #f8f8f8;
$gray: #5a5a5a;
$black: #23262f;
$red: #f3425b;
$green: #28a745;
$yellow: #ffc107;
$dark-purple: #394480;

// Fonts
$font: 'Avenir Next', sans-serif;
$font-h1: 53px;
$font-h2: 40px;
$font-h3: 25px;
$font-h4: 18px; //20px in XD
$font-large: 18px;
$font-normal: 14px;
$font-small: 12px;

// Font weights
$font-weight-ultralight: 200;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-demibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 900;

// Spacing
$spacing-004: 4px;
$spacing-008: 8px;
$spacing-010: 10px;
$spacing-012: 12px;
$spacing-016: 16px;
$spacing-024: 24px;
$spacing-032: 32px;
$spacing-048: 48px;
$spacing-064: 64px;
$spacing-096: 96px;
$spacing-128: 128px;

// Radius
$border-radius-5: 5px;
$border-radius-10: 10px;

// Effects
$transition: all 0.2s ease-in-out;
$scale: scale(1.05);

// HomeMap
$pin-color: #3d5af1;

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 14.5px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    padding-right: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($black, 0.15);
    border-radius: 999px;
    cursor: pointer;
    border: 5px solid white;
    border-top: 6px solid white;
    border-bottom: 6px solid white;
  }
}
