@import 'src/styles/variables';

.data-bar {
  height: 75vh;
  max-height: 100%;
  width: 300px;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: $border-radius-10;
  padding: 20px;
  overflow: hidden;
  display: grid;
  gap: $spacing-032;
  grid-template-rows: auto 1fr;
  .top {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: $spacing-012;
    position: relative;

    .border-left {
      width: 3px;
      height: 100%;
      background-color: rgba($black, 0.15);
      margin-left: -21px;
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
      position: absolute;
      left: 0;

      &.active,
      &.online {
        background: $green;
      }

      &.offline {
        background: $red;
      }
    }

    .name {
      font-weight: $font-weight-demibold;
      font-size: $font-large;
    }

    .icon {
      background-color: $black;
      color: $white;
      height: 15px;
      width: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      font-size: $font-small;
      font-weight: $font-weight-demibold;
    }

    .route {
      grid-column: 1 / -1;
    }
  }
  .bottom {
    display: grid;
    gap: $spacing-024;
    overflow: auto;
    max-height: 100%;
    margin-right: -20px;
    padding-right: 20px;
  }
}
