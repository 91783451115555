@import '../../../styles/variables';

.SideNav {
  position: fixed;
  height: 100vh;
  background-color: $white;
  border: 2px solid $light-gray;
  width: 60px;
  left: 0;
  top: 0;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  padding: $spacing-016 0;
  z-index: 3;

  .top {
    display: grid;
    justify-content: center;
    gap: 12px;
    height: fit-content;
    align-self: start;

    svg {
      color: $blue;
      font-size: 26px;
    }
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $spacing-008;
    width: 100%;
  }

  .bottom {
    display: grid;
    justify-content: center;
    gap: $spacing-012;
    height: fit-content;
    align-self: end;
  }
}
