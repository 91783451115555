@import '../../../styles/variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

.app-store-details {
  background: #F8F8F8;
  padding: 2% calc(5px + 5%) 2% calc(18px + 5%);
}

.description-apps {
  min-width: 200px;
  width: 40%;
  max-width: calc(100% - 120px);
}

.short {
  line-height: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.underline-link {
  text-decoration: underline;
  color: #3E5AF1;
  cursor: pointer;
}

.app-details-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.image-container {
  margin-top: 30px;
  width: 50%;
  min-width: 200px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.image {
  margin-right: 3%;
  object-fit: cover;
  width: 100%;
  min-width: 200px;
  max-width: 700px;
  max-height: 472px;
  height: auto;
}

.add-favorite-btn {
    width: 200px;
    height: 40px;
    background-color: #3D59EE;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #ffffff;
    &:hover {
        background-color: #1635D8;
    }
    svg {
        font-size: 20px;
    }
}


.app-header {
  display: flex;
  align-items: center;
}

.image-logo {
  height: 4em;
  margin-right: 25px;
}
