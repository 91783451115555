.app-store-card {
  min-width: calc(33% - 100px);
  max-width: 300px;
  min-height: 80px;
  padding: 10px;
  border-radius: 5px;
  border: 3px solid transparent;
  background: #f5f5f5;
}

.app-store-card:hover {
  cursor: pointer;
  border-color: #c2d9f8;
}

.app-store-card.selected {
  border: 3px solid #1a73e8;
}

.app-description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}

.app-logo {
  width: 80px;
  height: 80px;
  background: #fff;
  padding: 4px;
  border-radius: 5px;
}
