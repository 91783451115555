.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  background-color: white;
  padding: 30px;
  border-radius: 6px;
  margin: 3% 10% 5% calc(20px + 10%);
}

.modal {
  max-width: 1050px;
  max-height: 800px;
}

.content {

}

.favorite-apps-container {

}

.app-container {
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
}

.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line {
  border-bottom: 2px solid #818181;
  padding-bottom: 10px;
}

.close {
  font-size: 3em;
  font-weight: normal;
}

.favorite-apps-container {

}
