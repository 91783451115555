@import 'src/styles/variables';

input {
  height: 40px;
  background-color: #fff;
  border: 1.5px solid #f8f8f8;
  color: $black;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
