@import '../styles/variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

.cards-grid {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: $spacing-012;
  max-width: 100%;
  overflow-y: auto;

  .card {
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  }

  @media only screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707071;
}

.map-overlay-left {
  position: fixed;
  left: 6.5rem;
  top: 3rem;
  width: 100%;
  max-width: 252px;
  max-height: 84%;
  display: grid;
  height: fit-content;
  grid-template-rows: auto 1fr;
  .heading {
    display: flex;
    gap: $spacing-016;
    align-items: center;
    margin-bottom: $spacing-016;
    svg {
      font-size: 25px;
    }
  }
}

.map-overlay-right {
  position: absolute;
  right: 0;
  top: calc($spacing-048 + 30px + $spacing-048);
  padding: 0 $spacing-048 $spacing-048 0;
}

.map-overlay-right-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 $spacing-048 36px 0;

  .map-layer {
    color: black;
    background-color: $white;
    border-color: white;
    padding: 3px;
    width: 40px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  }
}

.map-overlay-report-log-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 $spacing-048 $spacing-048 0;
}

.map-overlay-report-log-top-right {
  position: fixed;
  right: 0;
  top: 3rem;
  padding: 0 $spacing-048 $spacing-048 0;
}

.map-overlay-blurred {
  pointer-events: none;
}

.__dumi-default-previewer-actions button[role='change-jsx'] {
  background-position: 0 !important;
}

.container {
  position: relative;
  padding: $spacing-048;
}

.dimmed {
  opacity: 0.5;
  pointer-events: none;
}
