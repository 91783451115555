@import '../../styles/variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

.app-store {
  background: #F8F8F8;
}

.favorite-apps{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.app-card-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 30px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  width: auto;
}

.app-card-scroll-container::-webkit-scrollbar {
  width: 12px;
  height: 0;
}

.app-card-scroll-container::-webkit-scrollbar-thumb {
  background-color: #F8F8F8;
}

.app-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  max-width: fit-content;
}

.scroll-button {
  width: 40px;
  height: 40px;
  background-color: $white;
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-left: 0;
  &:hover {
    background-color: #ffffff;
  }
  svg {
    font-size: 20px;
  }
}

.left-btn {
  position: relative;
  top: 40px;
  transform: translateY(-50%);
}

.right-btn {
  position: relative;
  right: -80px;
  transform: translateY(-50%);
}

.btn-placement {
  margin-left: calc(3% + 50px);
  left: calc(3% + 50px);
}

.app-card {
  background: $white;
  cursor: pointer;
  max-width: 340px;
  max-height: 251px;
  min-height: 251px;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.center {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.app-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.app-section h2 {
  margin-left: 40px;
}
