@import '../../../styles/variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

.app-card {
  background: $white;
  cursor: pointer;
  width: 340px;
  height: 251px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
