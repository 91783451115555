@import '../../../../styles/variables';

.NavItem {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.5px;
  border-right: 5px solid transparent;
  cursor: pointer;

  svg {
    color: #9a9a9a;
    font-size: 22px;
  }

  &.active {
    background-color: $light-gray;
    border-right: 4px solid $blue;

    svg {
      color: $blue;
    }
  }
}
