.report-log-container {
  background-color: #ffffff;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.75rem;
  overflow: hidden;
}

.report-log-header {
  color: #000000;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.report-log-sub-header {
  color: #000000;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

.report-log-content {
  .report-log-error-item,
  .report-log-warning-item {
    background-color: #ffffff;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 0.875rem;
    padding: 0.7rem;
    border-radius: 0.6rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 2px double;

    .error-header,
    .warning-header {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    &:hover {
      background-color: #eee;
    }
  }

  .report-log-error-item {
    border-color: #3d5af1;
  }

  .report-log-warning-item {
    border-color: #f1d20c;
  }
}

.report-log-error-list-container {
  padding-right: 10px;
  max-height: 330px;
  overflow-y: auto;
}
