@import 'src/styles/variables';

.TestPage {
  label {
    font-weight: 600;
  }
  .group {
    margin-bottom: 50px;
  }
}
