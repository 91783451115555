.minimized-report-log-container {
  background-color: #ffffff;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.75rem;
  overflow-y: auto;
  max-height: 420px;
}

.minimized-report-log-header {
  color: #000000;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.minimized-report-log-sub-header {
  color: #000000;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

.minimized-report-log-content {
  .minimized-report-log-item {
    background-color: #ffffff;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 0.875rem;
    padding: 0.7rem;
    border-radius: 0.6rem;
    border: 2px double #3d5af1;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #eee;
    }

    .minimized-error-header {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .minimized-error-header-message {
      color: #e0515f;
      font-size: 0.9rem;
      line-height: normal;
    }

    &.opened {
      .toggle-icon {
        transform: rotate(180deg);
      }
    }
  }

  .minimized-toggle-icon {
    cursor: pointer;
    transition: transform 0.3s;
  }
}
